import './skip-link-focus-fix';
import AOS from 'aos';
import 'script-loader!slick-carousel';
import 'script-loader!lity';

(function($) {
	AOS.init({ once: true, duration: 500 });

	$(document).ready(function() {
		menuToggle();
		accordionShortcode();
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

})(jQuery);